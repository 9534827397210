import React, { useState, useEffect, useContext, useRef } from "react";
import useModal from "../lib/useModal";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { bio } from "../data/pages/about";
import { graphql } from "gatsby";
import "../styles/pages/about.scss";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { aboutImageData } from "../data/pages/about";
import EntryPage from "../components/EntryPage";
import useSectionHighlight from "../lib/useSectionHighlight";
import ReactContext from "../context/reactContext";

const About = ({ data }) => {
  const aboutRef = useRef(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const { openModal } = useModal();
  const { setCurrentInterest, interestFilter } = useContext(ReactContext);
  const {
    allFile: { edges: allImages },
  } = data;

  useSectionHighlight(aboutRef);

  const aboutImages = assetArrayToObject({ useGatsbyImage: true, images: allImages });

  useEffect(() => {
    if (currentPhoto) {
      const ModalContent = (
        <div className="about-current-photo">
          {currentPhoto && (
            <GatsbyImage
              image={aboutImages[currentPhoto]}
              width={480}
              alt={currentPhoto}
            />
          )}
        </div>
      );

      openModal(ModalContent);
    }
    setCurrentPhoto(null);
  }, [currentPhoto]);

  return (
    <>
      {/* <EntryPage text="about me!" /> */}
      <div ref={aboutRef} className="db-about">
        <section id="db-about__bio" className="db-about__bio">
          <div className="db-about__bio__inner">
            <h2>Introduction</h2>
            <div className="db-about__bio__content">
              <>
                <StaticImage
                  className="bio-image"
                  width={480}
                  src="../images/daniel2.jpg"
                  alt="Daniel-Bogart"
                />
                {bio.map((text, index) => {
                  return <p key={index}>{text}</p>;
                })}
              </>
            </div>
          </div>
        </section>
        <section id="db-about__interests" className="db-about__interests">
          <div className="db-about__interests__inner">
            <h2>Other Interests</h2>
            <div className="db-about__interests__content">
              {aboutImageData
                .filter((el) =>
                  interestFilter ? interestFilter === el.category : true
                )
                .map((item) => {
                  const { imageName, name, description, category } = item;
                  const interestInfo = {
                    name: name,
                    description: description,
                    category: category,
                  };
                  return (
                    <GatsbyImage
                      className="cursor-pointer"
                      key={name}
                      width={300}
                      onClick={() => setCurrentPhoto(imageName)}
                      layout="constrained"
                      alt={imageName}
                      image={aboutImages[imageName]}
                      onMouseEnter={() => setCurrentInterest(interestInfo)}
                      onMouseLeave={() => setCurrentInterest(null)}
                    />
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "about" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          id
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              aspectRatio: 1
              width: 480
            )
          }
        }
      }
    }
  }
`;

export default About;
